<template>
  <div class="quick-toolbar">
    <div class="wrapper">
      <p class="title">什么是商品订单？</p>
      <p class="desc">
        员工可以在EduSoho销客助手售卖课程和班级。员工为客户创建专属订单，客户支付订单后，系统自动为客户分配账号和权限，同时订单金额归属到当前员工的业绩中，为业绩评价提供依据。
      </p>
    </div>

    <div class="wrapper">
      <div class="mb16">
        <span class="title">如何使用？</span>
        <router-link class="ml8" :to="{name: 'system_setting_chat_toolbar'}">配置教程</router-link>
      </div>
      <p class="desc">
        配置成功后，员工登录企业微信，在与客户的聊天对话框右侧点击侧边栏的图标，即可看到商品订单工具栏。
      </p>
    </div>

    <div class="wrapper">
      <a-image class="display-img"
        src="https://ese9a2b9c8d2vx-pub.pubssl.qiqiuyun.net/persondialogue/image/903a5ebd5a115b44c2048eb17a947858"
        alt="商品订单图" />
    </div>
  </div>
</template>

<script>
import { Image } from "ant-design-vue";

export default {
  name: "GoodsOrder",
  components: {
    AImage: Image,
  },
};
</script>